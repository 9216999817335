.FI h2 {
    font-size: 2rem;
    text-align: left;
    padding: 2rem 0rem 0rem;
}

.FI h3 {
    font-size: 1.5rem;
    text-align: left;
    padding: 2rem 1rem 0rem;
}

.FI h4 {
    font-size: 1.125rem;
    text-align: left;
    font-weight: bold;
    padding: 2rem 1rem 0rem;
}

.FI p {
    font-size: 1rem;
    padding: 0.5rem 1rem 0rem;
}

.FI ol {
    font-size: 1rem;
    padding: 0.5rem 2rem 0rem;
}

.FI li {
    font-size: 1rem;
    margin: 0.5rem 2rem;
    /* padding: 0rem 0rem 0.5rem; */
}

.FI {
    text-align: left;
    color: #1D1D1F;
    background-color: #ffffff;
}

.extra__small__image {
    width: 40%;
    margin: 2rem auto;
    border-radius: 0rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
}

.small__image {
    width: auto;
    margin: 2rem 1rem;
    border-radius: 0rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
}

.medium__image {
    width: auto;
    margin: 2rem 1rem;
    border-radius: 0rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
}

.medium__image__noshadow {
    width: auto;
    margin: 2rem 1rem;
    border-radius: 0rem;
}


.large__image {
    width: auto;
    margin: 2rem 1rem;
    border-radius: 0rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
}

.logo {
    margin: 9rem auto 12rem;
    height: 80px;
}

.section__name {
    margin: 0rem 1rem 0rem 1rem;
    /* border-bottom: 2px solid #000000; */
}

.space {
    opacity: 0%;
}

#success__story {
    font-size: 1rem;
    font-weight: bold;
    color:#1D1D1F;
}
/* .group__image {
    margin: 2rem 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
    border-radius: 1rem;
} */



@media only screen and (min-width: 1080px) {
    .project__container {
        padding: 0 0vw 20vh 0vw;
    }

    .FI h2 {
        padding: 2rem 0rem 0rem 0.5rem;
    }
    
    .FI h3 {
        padding: 2rem 12.5rem 0rem 0.5rem;
    }
    
    .FI h4 {
        padding: 2rem 12.5rem 0rem 0.5rem;
    }
    
    .FI p {
        padding: 0.5rem 18rem 0rem 0.5rem;
    }
    
    .FI ol {
        padding: 0.5rem 12.5rem 0.5rem 1.5rem;
    }
    
    .FI ul {
        padding: 0.5rem 12.5rem 0.5rem 1.5rem;
    }
    .FI li {
        margin: 0rem 0rem 0rem 0rem;
    }

    /* .FI .list {
        margin: 0rem 13.5rem 0rem 0rem;
        padding: 0.5rem 0rem;
    } */

    .FI .extra__small__image {
        width: 25%;
        margin: 2rem 0rem 2rem 0.5rem;
        border-radius: 1rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);

    }
    
    .FI .small__image {
        width: auto;
        margin: 2rem 25rem 2rem 0.5rem;
        border-radius: 1rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
    }
    
    .FI .medium__image {
        width: auto;
        margin: 2rem 18rem 2rem 0.5rem;
        border-radius: 1rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
    }

    .FI .medium__image__noshadow {
        width: auto;
        margin: 2rem 18rem 2rem 0.5rem;
        border-radius: 1rem;
    }
    
    .FI .large__image {
        width: 70vw;
        margin: 2rem 0.5rem;
        border-radius: 1rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
    }

    .FI .section__name {
        margin: 5rem 12.5rem 0rem 0rem;
    }

    .FI .logo {
        /* width: 70vw; */
        width: auto;
        margin: 10rem 18rem 10rem 0.5rem;
        /* margin: 10rem 0rem; */
    }

}
  